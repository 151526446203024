<script lang="ts" setup>
import { ArticleProps } from "./Article.props"

const dynamicId = computed(() => `link-${Math.random()}`)

const triggerLink = () => document.getElementById(dynamicId.value)?.click()

defineProps<ArticleProps>()
</script>

<template>
  <div @click="triggerLink" class="article cursor-pointer space-y-2">
    <figure v-if="!!image" class="article__image h-[7rem]">
      <UtilsWrapperImage
        :="image"
        class="wrapper-image-full-container-centered"
      />
    </figure>

    <AppLink :to="path" :id="dynamicId" class="block">
      <h6 v-if="!!title" class="pig-bold line-clamp-1">{{ title }}</h6>
    </AppLink>

    <div v-if="tags.length" class="article__tags space-x-4">
      <RailsUtilsTag v-for="tag in tags" :="tag" />
    </div>
    <UtilsMarkdown v-if="!!text" class="mouse line-clamp-4" :content="text" />
    <UtilsButton
      v-if="!!path"
      :path="path"
      theme="ghost-black"
      class="inline-block [&>*]:pl-0"
      order="last"
      :text="$t('rails.utils.article.read')"
      text-underline
    >
      <template #icon>
        <UtilsIcon name="ArrowRight.svg" class="h-5 w-5" />
      </template>
    </UtilsButton>
  </div>
</template>
